import { ClickElement, Label, Modal, mergeClassNames } from 'iiomi-ui';
import { IWebComponentProps } from '../../../../interfaces/BaseComponentInterface';
import getNames from './getNames';
import useCss from './useCss';
import React from 'react';
import { Ability, FeaturesData } from '../../../../pages/CharacterFeaturesPage/CharacterFeaturesPage';
import { onValue, query, ref } from 'firebase/database';
import { db, fireStore, useAuth } from '../../../../firebase';
import { ICharacterInFirebase, IFeatureSlot } from '../../../../interfaces/FirebaseInterfaces';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { FeatureCard } from '../../../FeatureCard';

export interface IFeatureSelectModalProps extends IWebComponentProps {
    onClose: () => void;
    onSelectionButtonClick: (selectedFeature: Ability) => void;
    characterName?: string;
    selectedIndex: number;
    allFeatureSlots?: IFeatureSlot[];
}

interface CategoryWithFeatures {
    name: string;
    features: Ability[];
}

function FeatureSelectModal(props: IFeatureSelectModalProps) {
	const { ID, CLASS } = useCss(getNames('not_relevant'));
	const { user } = useAuth();

	const [featuresLoaded, setFeaturesLoaded] = React.useState(false);
	const [features, setFeatures] = React.useState<FeaturesData>({});
	const [character, setCharacter] = React.useState<ICharacterInFirebase>();
	const [characterLoaded, setCharacterLoaded] = React.useState(false);

	React.useEffect(() => {
		const fetchFeatures = async () => {
			const dbRef = ref(db, 'Features');
			onValue(query(dbRef), snapshot => {
				setFeatures(snapshot.val());
			});
			setFeaturesLoaded(true);
		};
		const fetchCharacter = async () => {
			const characterSnapshot = await getDoc(doc(fireStore, 'Users', `123`, 'Characters', `${props.characterName}`));
			setCharacter(characterSnapshot.data() as ICharacterInFirebase);
			setCharacterLoaded(true);
		};
		fetchFeatures();
		fetchCharacter();
	}, []);

	const shouldLoad = featuresLoaded && characterLoaded && props.characterName;

	/*let exp;
	let archetype;
	let selectedClass;

	if (character) {
		exp = character.exp;
		archetype = character.archetype;
		selectedClass = character.class;
	}*/

	const filteredCategories = filterAndGroupFeatures(features, character);

	console.log(filteredCategories);

	return <Modal
		id={ID.ROOT}
		className={mergeClassNames(CLASS.ROOT, props.className)}
		onClose={props.onClose}
		title='Features:'
	>
		<div id={ID.MODAL_BODY} className={CLASS.MODAL_BODY}>
			{!shouldLoad && <p>Loading...</p>}
			{shouldLoad && <div id={ID.LOADED} className={CLASS.LOADED}>
				{filteredCategories.map(category => (
					<div key={category.name} id={ID.CATEGORY} className={CLASS.CATEGORY}>
						<Label id={ID.CATEGORY_TITLE} className={CLASS.CATEGORY_TITLE} tag={'h4'}>{category.name}</Label>
						<div id={ID.FEATURES} className={CLASS.FEATURES}>
							{category.features.map(feature => <ClickElement key={feature.name} id={''} onClick={() => {
								setFeatureSlot(props.selectedIndex, feature, user, props.characterName, props.allFeatureSlots, feature.background);
								console.log('Feature selected:', feature);
								props.onClose();
							}}>
								<FeatureCard feature={feature} name={feature.name} id={ID.FEATURE} className={CLASS.FEATURE} />
							</ClickElement>
							)}
						</div>
					</div>
				))}
			</div>}
		</div>
	</Modal>;
}

function filterAndGroupFeatures(firstObject: any, secondObject?: ICharacterInFirebase): CategoryWithFeatures[] {
	if (!secondObject) {
		return [];
	}

	const { totalExp, archetype, class: selectedClass } = secondObject;
	const filteredCategories: CategoryWithFeatures[] = [];
	const alreadyTookBackground = secondObject.backgroundSelected;

	for (const category in firstObject) {
		const categoryFeatures = firstObject[category];
		const filteredFeatures: Ability[] = [];

		for (const featureName in categoryFeatures) {
			const feature = categoryFeatures[featureName];
			if ((category === archetype && (feature.required === selectedClass || !feature.required)) ||
                (category === 'Background' && !alreadyTookBackground)) {
				if (!feature.cost || feature.cost <= totalExp) {
					filteredFeatures.push(feature);
				}
			}
		}
		if (filteredFeatures.length > 0) {
			filteredCategories.push({ name: category, features: filteredFeatures });
		}
	}

	return filteredCategories;
}


function setFeatureSlot(slotIndex: number, feature: Ability, user: any, characterName?: string, allFeatureSlots?: IFeatureSlot[], isBackground?: boolean) {
	if (!characterName || !allFeatureSlots) {
		return console.error('No character name or feature slots provided');
	}

	const setFeatureSlot = async () => {
		if (slotIndex < 0 || slotIndex >= allFeatureSlots.length) {
			console.error('Invalid slot index');
			return;
		}

		allFeatureSlots[slotIndex].selected = feature;

		if (isBackground) {
			return await updateDoc(doc(fireStore, 'Users', `${user.uid}`, 'Characters', `${characterName}`), { allFeatureSlots, backgroundSelected: isBackground });
		}
		await updateDoc(doc(fireStore, 'Users', `${user.uid}`, 'Characters', `${characterName}`), { allFeatureSlots });
	};
	setFeatureSlot();
}

FeatureSelectModal.getNames = getNames;

export default FeatureSelectModal;