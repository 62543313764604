import { generateNames } from 'iiomi-ui';

export default (id: string) => generateNames(
	id,
	'MAIN_CONTAINER',
	'IMAGE_CONTAINER',
	'IMAGE',
	'IMAGE_BUTTON',
	'EFFECTS_CONTAINER',
	'EFFECT_CARD',
	'EFFECT_CARD_TITLE',
	'EFFECT_CARD_DESCRIPTION',
	'EFFECT_CARD_LABEL',
	'PICTURE_SELECTION_MODAL',
	'EFFECT_CARD_HEADER',
	'DIVIDER',
);