import React from 'react';
import getNames from './getNames';
import useCss from './useCss';
import { CharacterCreation } from '../../components/CharacterCreation';
import {IWebComponentProps} from '../../interfaces/BaseComponentInterface';

export interface ICharactersOverviewPageProps extends IWebComponentProps {
}

function CharacterCreationPage(props: ICharactersOverviewPageProps) {

	const { ID, CLASS } = useCss(getNames(props.id));

	return<CharacterCreation id={ID.ROOT} className={CLASS.ROOT} />;
}

CharacterCreationPage.getNames = getNames;

export default CharacterCreationPage;
