import React from 'react';
import {IWebComponentProps} from '../../../interfaces/BaseComponentInterface';
import useCss from './useCss';
import getNames from './getNames';
import {ArrowIcon, Divider, Label, mergeClassNames} from 'iiomi-ui';

export interface ICharacterCardHeaderProps extends IWebComponentProps {
	archetype: string;
	name: string;
	race: string;
	class: string;
	level?: number;
	isPreview?: boolean;
	isOpen?: boolean;
}

function CharacterCardHeader(props: ICharacterCardHeaderProps) {

	const {ID, CLASS} = useCss(getNames(props.id));

	return <div
		id={ID.ROOT}
		className={mergeClassNames(CLASS.ROOT, props.className)}
	>
		<div id={ID.CARD_HEADER} className={CLASS.CARD_HEADER}>
			<div id={ID.CARD_HEADER_DETAILS} className={CLASS.CARD_HEADER_DETAILS}>
				<div id={ID.A_AND_N_WRAPPER} className={CLASS.A_AND_N_WRAPPER}>
					<Label id={ID.ARCHETYPE} className={CLASS.ARCHETYPE} tag={'label'} size={'small'}>
						{props.archetype}
					</Label>
					<Label id={ID.NAME} className={CLASS.NAME} tag={'h4'}>
						{props.name}
					</Label>
				</div>
				<Label id={ID.RACE} className={CLASS.RACE} tag={'h5'}>
					{props.race}
				</Label>
				<Label id={ID.CLASS} className={CLASS.CLASS} tag={'h5'}>
					{props.class}{!props.isPreview && ` - Level ${props.level}`}
				</Label>
			</div>
			{props.isPreview ? undefined :
				<ArrowIcon className={mergeClassNames(CLASS.ARROW, {[CLASS.OPEN]: props.isOpen})}/>}
		</div>
		{props.isOpen && <Divider id={ID.DIVIDER} className={CLASS.DIVIDER} layout={'horizontal'}/>}
	</div>;

}

export default CharacterCardHeader;