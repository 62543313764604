import React from 'react';
import {IWebComponentProps} from '../../interfaces/BaseComponentInterface';
import {Card, IAccordionChildProps, Label, mergeClassNames} from 'iiomi-ui';
import getNames from './getNames';
import useCss from './useCss';
import {FeatureSlots} from './FeatureSlots';
import {CharacterValues} from './CharacterValues';
import {MainContainer} from './MainContainer';
import {ICharacterInFirebase} from '../../interfaces/FirebaseInterfaces';
import {CharacterCardHeader} from './CharacterCardHeader';

interface ICharacterCardProps extends IAccordionChildProps, IWebComponentProps {
	variant: 'preview' | 'full';
	character?: ICharacterInFirebase;
	collapsible?: boolean;
	setCharacterPicture?: (imageUrl: string) => void;
	allAvailablePictures?: string[];
}

function CharacterCard(props: ICharacterCardProps) {
	const {ID, CLASS} = useCss(getNames(props.id));
	if (!props.character) {
		return null;
	}

	const featureSlotAmount = props.character.featureSlots;
	const specialFeatureSlots = props.character.extraSlot;
	const isPreview = props.variant === 'preview';
	const isOpen = props.isOpen;

	return <Card
		id={ID.ROOT}
		className={mergeClassNames(CLASS.ROOT, props.className)}
		header={<CharacterCardHeader
			id={ID.HEADER}
			className={CLASS.HEADER}
			archetype={props.character.archetype}
			name={props.character.name}
			race={props.character.race}
			class={props.character.class}
			isPreview={isPreview}
			isOpen={isOpen}
			level={isPreview ? undefined : props.character.level}
		/>}
		onHeaderClick={props.onToggle}
		isOpen={isOpen}
		collapsible={props.collapsible}
	>
		<div id={ID.CARD_BODY} className={CLASS.CARD_BODY}>
			<MainContainer
				id={ID.MAIN_CONTAINER}
				className={CLASS.MAIN_CONTAINER}
				image={props.character.imageUrl}
				effects={props.character.effects}
				isPreview={isPreview}
				setCharacterPicture={isPreview ? props.setCharacterPicture : undefined}
				allAvailablePictures={isPreview ? props.allAvailablePictures : undefined}
			/>
			<FeatureSlots
				id={ID.FEATURE_SLOTS}
				className={mergeClassNames(CLASS.SIDE, CLASS.FEATURE_SLOTS)}
				featureSlots={featureSlotAmount}
				specialFeatureSlots={specialFeatureSlots}
				isPreview={isPreview}
				allFeatureSlots={!isPreview ? props.character.allFeatureSlots : undefined}
				characterName={props.character.name}
				availableExp={props.character.exp}
			/>
			<CharacterValues
				id={ID.EQUPMENT_PACK}
				className={mergeClassNames(CLASS.SIDE, CLASS.EQUPMENT_PACK)}
				startingEqPack={props.character.eqPack}
				startingHP={props.character.startingHP}
				startingGold={props.character.startingGold}
				startingHand={props.character.startingHand}
				equipmentLimits={props.character.itemLimit}
				isPreview={isPreview}
				currentExp={props.character.exp}
				totalExp={props.character.totalExp}
				items={props.character.itemPack}
			/>
			<div id={ID.FOOTER} className={CLASS.FOOTER}>
				<Label
					id={ID.QUOTE}
					className={CLASS.QUOTE}
					tag={'label'}
				>
					&quot;{props.character.flavorTxt}&quot; - {props.character.name}
				</Label>
			</div>
		</div>
	</Card>;
}

export default CharacterCard;