import React from 'react';
import { Button, Modal, mergeClassNames } from 'iiomi-ui';
import { IWebComponentProps } from '../../../interfaces/BaseComponentInterface';
import getNames from './getNames';
import useCss from './useCss';
import { IDatabaseClassList } from '../../../interfaces/FirebaseInterfaces';

export interface IClassSelectionModalProps extends IWebComponentProps {
    availableClasses: IDatabaseClassList;
    onClose: () => void;
    onSelectionButtonClick: (archetype: string, selectedClass: string) => void;
}

function ClassSelectionModal(props: IClassSelectionModalProps) {
	const { ID, CLASS } = useCss(getNames(props.id));
	console.log('availableClasses', props.availableClasses);
	return <Modal
		id={ID.ROOT}
		className={mergeClassNames(CLASS.ROOT, props.className)}
		onClose={props.onClose}
		title='Select a class!'
	>
		<div id={ID.MODAL_BODY} className={CLASS.MODAL_BODY}>
			{Object.keys(props.availableClasses).map((className, index) => (
				<Button
					id={ID.CLASS_BUTTON}
					className={mergeClassNames(CLASS.CLASS_BUTTON, {
						// @ts-ignore
						[CLASS.MARTIAL]: props.availableClasses[className].archetype === 'Martial',
						// @ts-ignore
						[CLASS.DECEPTION]: props.availableClasses[className].archetype === 'Deception',
						// @ts-ignore
						[CLASS.DEVOTION]: props.availableClasses[className].archetype === 'Devotion',
						// @ts-ignore
						[CLASS.ARCANE]: props.availableClasses[className].archetype === 'Arcane',
					})}
					key={index}
					onClick={() => props.onSelectionButtonClick('Martial', className)}
				>
					{className}
				</Button>
			))}
		</div>
	</Modal>;
}

ClassSelectionModal.getNames = getNames;

export default ClassSelectionModal;