import React from 'react';
import { Button, Container, Label } from 'iiomi-ui';
import { FormProvider, useForm } from 'react-hook-form';
import getNames from './getNames';
import useCss from './useCss';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../firebase';
import {ISignup} from '../../interfaces/FirebaseInterfaces';
import {IWebComponentProps} from '../../interfaces/BaseComponentInterface';

export interface ISignupPageProps extends IWebComponentProps {
}
function SignupPage(props: ISignupPageProps) {

	const { ID, CLASS } = useCss(getNames(props.id));
	const methods = useForm<ISignup>({ mode: 'onBlur' });

	const { signUp } = useAuth();
	const navigate = useNavigate();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = methods;

	const onSubmit = async (data: ISignup) => {
		try {
			signUp(data);
			navigate('/');
		} catch (error) {
			console.error(error);
		}
	};

	return <Container id={ID.ROOT} className={CLASS.ROOT}>
		<Label id={ID.TITLE} className={CLASS.TITLE} tag='label'>
            Sign Up
		</Label>
		<FormProvider {...methods}>
			<form action='' onSubmit={handleSubmit(onSubmit)} className={CLASS.FORM}>
				<div>
					<div>
						<label htmlFor=''>
                            Email *
						</label>
					</div>
					<input
						type='email'
						{...register('email', { required: 'Email is required' })}
					/>
					{errors.email && <p>{errors.email.message}</p>}
				</div>
				<div>
					<div>
						<label htmlFor=''>
                            Password *
						</label>
					</div>

					<input
						type='password'
						{...register('password', { required: 'Password is required' })}
					/>
					{errors.password && <p>{errors.password.message}</p>}
				</div>
				<div>
					<div>
						<label htmlFor=''>
                            Confirm Password *
						</label>
					</div>

					<input
						type='password'
						{...register('password_confirm', {
							required: 'Verify your password',
						})}
					/>
					{errors.password_confirm && (
						<p>{errors.password_confirm.message}</p>
					)}
				</div>
				<div>
					<Button id={ID.SUBMIT_BUTTON} className={CLASS.SUBMIT_BUTTON} onClick={() => { }}> Submit </Button>
				</div>
			</form>
		</FormProvider>
	</Container>;
}

export default SignupPage;