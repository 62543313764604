import {get, ref as dbRef} from 'firebase/database';
import {getDownloadURL, listAll, ref as storageRef} from 'firebase/storage';
import {
	collection,
	doc,
	DocumentData,
	FirestoreDataConverter,
	getDoc,
	getDocs,
	QueryDocumentSnapshot,
	setDoc,
	WithFieldValue
} from 'firebase/firestore';
import {db, fireStorage, fireStore} from '../firebase';
import {
	ICharacterInFirebase,
	IClassRaceEffect,
	IDatabaseClassList,
	IEffectsWithDetails,
	IFeatureSlot,
	IFirestoreUser
} from '../interfaces/FirebaseInterfaces';

export async function getMaxAmountOfCharacters(user?: any): Promise<number> {
	if (!user) {
		return 0;
	}
	const userSnapshot = await getDoc(doc(fireStore, 'Users', `${user.uid}`).withConverter(firestoreConverter<IFirestoreUser>()));

	if(!userSnapshot.exists()){
		return 0;
	}
	const userData = userSnapshot.data();
	return userData?.maxCharacters ?? 0;
}

export async function getCharacters(user?: any): Promise<ICharacterInFirebase[]> {
	if (!user) {
		return [];
	}
	const charactersSnapshot = await getDocs(collection(fireStore, 'Users', `${user.uid}`, 'Characters').withConverter(firestoreConverter<ICharacterInFirebase>()));
	return charactersSnapshot.docs.map(doc => doc.data());
}

export async function getClasses(): Promise<IDatabaseClassList> {
	const classRef = dbRef(db, 'Classes');
	const classes = await get(classRef);
	if (!classes.exists()) {
		return {};
	}
	return classes.val();
}

export const getCharacterPictureUrl = async (race?: string, cClass?: string): Promise<string[]> => {
	const imagesRef = storageRef(fireStorage, `Characters`);
	if (!race || !cClass) {
		return [];
	}
	try {
		const res = await listAll(imagesRef);
		const matchedPathsPromises = res.items
			.filter(itemRef => {
				const [itemRace, itemClass] = itemRef.name.split('-');
				return itemRace === race && itemClass.startsWith(cClass);
			})
			.map(async itemRef => {
				return await getDownloadURL(itemRef);
			});
		return await Promise.all(matchedPathsPromises);
	} catch (error) {
		console.error('Error listing storage items:', error);
		return [];
	}
};

export async function getEffecstWithDetails(effects: IClassRaceEffect): Promise<IEffectsWithDetails> {
	const details = await Promise.all(Object.entries(effects).map(async ([effectKey]) => {
		const {effectTxt, name} = await getEffectText(effects[effectKey]);
		return {[effectKey]: {effectTxt, name}};
	}));
	return details.reduce((acc, current) => ({...acc, ...current}), {});
}

export const getEffectText = async (effect: string): Promise<any> => {
	const effectRef = dbRef(db, `CharacterEffects/${effect}`);
	try {
		const snapshot = await get(effectRef);
		if (snapshot.exists()) {
			return snapshot.val();
		} else {
			console.log('No data available for effect:', effect);
			return '';
		}
	} catch (error) {
		console.error('Error finding effects:', error);
		return '';
	}
};

export const getElementIcon = async (element: string): Promise<string> => {
	const iconsRef = storageRef(fireStorage, `Icons`);
	try {
		const res = await listAll(iconsRef);
		const matchedPathsPromises = res.items
			.filter(iconRef => iconRef.name.includes(element))
			.map(async iconRef => {
				const url = await getDownloadURL(iconRef);
				return url;
			});
		const matchedPaths = await Promise.all(matchedPathsPromises);
		return matchedPaths[0];
	} catch (error) {
		console.error('Error listing storage items:', error);
		return '';
	}
};

export const getNumberOfUsersCharacters = async (user: any): Promise<number> => {
	const charactersRef = dbRef(db, `Users/${user.uid}/Characters`);
	try {
		const snapshot = await get(charactersRef);
		if (snapshot.exists()) {
			return Object.keys(snapshot.val()).length;
		} else {
			return 0;
		}
	} catch (error) {
		console.error('Error finding characters:', error);
		return 0;
	}
};

export const isUserAllowedToCreateCharacter = async (user: any): Promise<boolean> => {
	const numberOfCharacters = await getNumberOfUsersCharacters(user);
	return numberOfCharacters < 3;
};

export const saveCharacter = async (selectedCharacter: any, pictureUrl: string, name: string, user: any) => {
	const featureSlotPrice = [5, 10, 15, 25, 40];
	const availableFeatureSlots = selectedCharacter.featureSlots ? (selectedCharacter.featureSlots - 1) : 5;

	const allFeatureSlots: IFeatureSlot[] = [
		{locked: false, description: 'Feature Slot 1'},
		...(Array.from({length: availableFeatureSlots}, (_, index) => {
			return {locked: true, description: 'Feature Slot ' + (index + 2), cost: featureSlotPrice[index]};
		}))
	];

	const extraSlotsCount = 5 - availableFeatureSlots;
	if (extraSlotsCount > 0 && selectedCharacter.extraSlot) {
		allFeatureSlots.push(
			...selectedCharacter.extraSlot.split(',').slice(0, extraSlotsCount).map((description: string) => ({
				locked: false, description
			}))
		);
	}

	selectedCharacter.allFeatureSlots = allFeatureSlots;

	await setDoc(doc(fireStore, 'Users', `${user.uid}`, 'Characters', `${name}`), {
		...selectedCharacter,
		name: name,
		exp: 0,
		totalExp: 0,
		level: 1,
		itemPack: [],
		imageUrl: pictureUrl
	});
};

export function firestoreConverter<T extends DocumentData>(): FirestoreDataConverter<T>{
	return {
		toFirestore(modelObj: WithFieldValue<T>): DocumentData {
			return modelObj;
		},
		fromFirestore(snapshot: QueryDocumentSnapshot): T {
			return snapshot.data() as T;
		}
	};
}
