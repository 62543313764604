import { css } from 'iiomi-ui';
import getNames from './getNames';

const { CLASS } = getNames('not_relevant');

export default css({
	[CLASS.ROOT]: {
		display: 'flex',
		height: '100%',
		padding: '2em',
		flexDirection: 'column',
		alignItems: 'center',
		rowGap: '1em',
		[css.descendant(CLASS.OPERATIONS)]: {
			display: 'flex',
			columnGap: '1em',
		},
	},
});