import { css } from 'iiomi-ui';
import getNames from './getNames';

const { CLASS } = getNames('not_relevant');

export default css({
	[CLASS.ROOT]: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		position: 'relative',
		height: '2.3em',
		width: '2.3em',
		[css.descendant(CLASS.NUMBER)]: {
			position: 'absolute',
			zIndex: '1',
			marginRight: '0.15em',
		},
		[css.descendant(CLASS.HEART)]: {
			position: 'absolute',
			width: '100%',
			height: '100%',
		},
	},
});
