import { css } from 'iiomi-ui';
import getNames from './getNames';

const { CLASS } = getNames('not_relevant');

export default css({
	[CLASS.ROOT]: {
		columnGap: '2em',
	},
	[CLASS.LINK]: {
		color: 'inherit',
		textDecoration: 'none',
	},
	[CLASS.LINK_BOX]: {
		display: 'flex',
		flexDirection: 'row',
		columnGap: '2em',
		justifyContent: 'flex-start',
		alignItems: 'center',
		flexGrow: '1',
		[css.descendant(CLASS.DIVIDER)]: {
			height: '2.5em',
		},
	},
});