import React from 'react';
import getNames from './getNames';
import useCss from './useCss';
import { CharactersOverview } from '../../components/CharactersOverview';
import {IWebComponentProps} from '../../interfaces/BaseComponentInterface';

export interface ICharactersOverviewPageProps extends IWebComponentProps {
}

function CharactersOverviewPage(props: ICharactersOverviewPageProps) {
	const { ID, CLASS } = useCss(getNames(props.id));

	return <CharactersOverview id={ID.ROOT} className={CLASS.ROOT} />;
}

CharactersOverviewPage.getNames = getNames;

export default CharactersOverviewPage;