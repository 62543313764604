import React from 'react';
import { Container, Label } from 'iiomi-ui';
import getNames from './getNames';
import useCss from './useCss';
import { db } from '../../firebase';
import { onValue, query, ref } from 'firebase/database';
import { FeatureAccordion } from '../../components/FeatureAccordion';
import {IWebComponentProps} from '../../interfaces/BaseComponentInterface';

export interface ICharacterFeaturesPageProps extends IWebComponentProps {
}

export interface Ability {
    required?: string;
    cost?: number;
    effectTxt: string;
    inGame: string;
    name: string;
    feature?: string;
    race?: string;
    background?: boolean;
}

export interface AbilityCategory {
    [key: string]: Ability;
}

export interface FeaturesData {
    [key: string]: AbilityCategory;
}

function CharacterFeaturesPage(props: ICharacterFeaturesPageProps) {

	const { ID, CLASS } = useCss(getNames(props.id));

	const [loaded, setLoaded] = React.useState(false);
	const [features, setFeatures] = React.useState<FeaturesData>({});

	React.useEffect(() => {
		const fetchFeatures = async () => {
			const dbRef = ref(db, 'Features');
			onValue(query(dbRef), snapshot => {
				setFeatures(snapshot.val());
			});
			setLoaded(true);
		};
		fetchFeatures();
	}, []);

	return <Container id={ID.ROOT} className={CLASS.ROOT}>
		{!loaded && <Label id={ID.TITLE} className={CLASS.TITLE} tag='h1'> Loading... </Label>}
		{loaded && (
			<>
				<Label id={ID.TITLE} className={CLASS.TITLE} tag='h1'>
                        Character Features
				</Label>
				<FeatureAccordion id={ID.CATEGORY} featuresData={features} />
			</>
		)}
	</Container>;
}

CharacterFeaturesPage.getNames = getNames;

export default CharacterFeaturesPage;