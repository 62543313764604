import React from 'react';
import { Button, mergeClassNames } from 'iiomi-ui';
import { IWebComponentProps } from '../../interfaces/BaseComponentInterface';
import getNames from './getNames';
import useCss from './useCss';
import { useNavigate } from 'react-router-dom';
import {getI18n} from '../../i18n';

export interface ICharacterOperationsProps extends IWebComponentProps {
}


function CharacterOperations(props: ICharacterOperationsProps) {
	const { ID, CLASS } = useCss(getNames(props.id));
	const navigate = useNavigate();
	const i18n = getI18n();

	const handleCreate = () => {
		navigate('/cCreation');
	};

	return <div id={ID.ROOT} className={mergeClassNames(CLASS.ROOT, props.className)}>
		<Button id={ID.CREATE_BUTTON} className={ID.CREATE_BUTTON} onClick={handleCreate} variant={'primary'}>{i18n.CREATE}</Button>
		<Button id={ID.DELETE_BUTTON} className={ID.DELETE_BUTTON} onClick={() => alert('Waiting for implementation!')} variant={'secondary'} >{i18n.DELETE}</Button>
	</div>;
}

export default CharacterOperations;