import {css} from 'iiomi-ui';
import getNames from './getNames';

const {CLASS} = getNames('not_relevant');

export default css({
	[CLASS.ROOT]: {
		display: 'flex',
		padding: '0.5em 1em',
		alignItems: 'center',
		flexDirection: 'column',
		[css.descendant(CLASS.CARD_HEADER)]: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			flexWrap: 'nowrap',
			alignItems: 'center',
			width: '100%',
			[css.descendant(CLASS.CARD_HEADER_DETAILS)]: {
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
				flexWrap: 'nowrap',
				alignItems: 'center',
				width: '100%',
				[css.descendant(CLASS.A_AND_N_WRAPPER)]: {
					display: 'inline-flex',
					flexDirection: 'column',
					justifyContent: 'space-evenly',
					alignItems: 'center'
				},
			},
			[css.descendant(CLASS.ARROW)]: {
				paddingLeft: '0.5em',
				cursor: 'pointer',
				transition: 'all 0.5s ease',
				width: '2em',
				[css.modifier(CLASS.OPEN)]: {
					transform: 'rotateX(180deg)',
				},
			},
		},
		[css.descendant(CLASS.DIVIDER)]: {
			margin: '0.5em 0',
		}
	}
});
