import {css} from 'iiomi-ui';
import getNames from './getNames';

const {CLASS} = getNames('not_relevant');

export default css({
	[CLASS.ROOT]: {
		display: 'flex',
		height: '100%',
		padding: '10px',
		flexDirection: 'column',
		alignItems: 'center'
	},
	[CLASS.FORM]: {
		display: 'flex',
		flexDirection: 'column',
		rowGap: '1em'
	},
	[CLASS.SUBMIT_BUTTON]: {
		width: '100%'
	}
});