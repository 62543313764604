import { css } from 'iiomi-ui';
import getNames from './getNames';

const { CLASS } = getNames('not_relevant');

export default css({
	[CLASS.ROOT]: {
		display: 'flex',
		flexDirection: 'row',
		rowGap: '0.5em',
		[css.descendant(CLASS.WRAPPER)]: {
			height: '80%',
			overflowX: 'auto',
		},
		[css.descendant(CLASS.MODAL_BODY)]: {
			[css.descendant(CLASS.LOADED)]: {
				display: 'flex',
				flexDirection: 'column',
				[css.descendant(CLASS.FEATURES)]: {
					display: 'flex',
					flexDirection: 'row',
					gap: '0.5em',
					flexWrap: 'wrap',
				},
			},
		},
	},
});
