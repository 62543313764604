import {generateNames} from 'iiomi-ui';

export default (id: string) => generateNames(
	id,
	'CHARACTER_CARD_HEADER',
	'CARD_HEADER',
	'CARD_HEADER_DETAILS',
	'DIVIDER',
	'A_AND_N_WRAPPER',
	'ARCHETYPE',
	'NAME',
	'RACE',
	'CLASS',
	'ARROW',
	'OPEN'
);