import React from 'react';
import useCss from './useCss';
import getNames from './getNames';
import {IWebComponentProps} from '../../../interfaces/BaseComponentInterface';
import {Card, ClickElement, Divider, Label, mergeClassNames} from 'iiomi-ui';
import {EditIcon} from '../../Icons';
import {getEffecstWithDetails} from '../../../helpers/firebaseHelpers';
import {IClassRaceEffect, IEffectsWithDetails} from '../../../interfaces/FirebaseInterfaces';
import {PictureSelectionModal} from './PictureSelectionModal';

export interface IMainContainerProps extends IWebComponentProps {
	effects: IClassRaceEffect;
	image: string;
	allAvailablePictures?: string[];
	isPreview?: boolean;
	setCharacterPicture?: (imageUrl: string) => void;
}

function MainContainer(props: IMainContainerProps) {
	const {ID, CLASS} = useCss(getNames(props.id));
	const [effectsWithDetails, setEffectsWithDetails] = React.useState<IEffectsWithDetails>({});
	const [pictureSelectModalOpen, setPictureSelectModalOpen] = React.useState<boolean>(false);

	const shouldRenderEffects = Object.keys(props.effects)[0] !== 'None';

	React.useEffect(() => {
		getEffecstWithDetails(props.effects)
			.then(data => {
				setEffectsWithDetails(data);
			})
			.catch(error => {
				console.error(error);
			});
	}, [props.effects]);


	return <div id={ID.ROOT} className={mergeClassNames(CLASS.ROOT, props.className)}>
		<div id={ID.IMAGE_CONTAINER} className={CLASS.IMAGE_CONTAINER}>
			{renderImage(ID, CLASS, props)}
		</div>
		{shouldRenderEffects &&
			<div id={ID.EFFECTS_CONTAINER} className={CLASS.EFFECTS_CONTAINER}>
				{Object.entries(effectsWithDetails).map(([key, {name, effectTxt}]) => (
					<Card
						id={ID.EFFECT_CARD}
						className={CLASS.EFFECT_CARD}
						key={key}
						header={
							<div id={ID.EFFECT_CARD_HEADER} className={CLASS.EFFECT_CARD_HEADER}>
								<Label
									id={ID.EFFECT_CARD_TITLE}
									className={CLASS.EFFECT_CARD_TITLE}
									tag={'h5'}
								>
									{name}
								</Label>
								<Divider id={ID.DIVIDER} className={ID.DIVIDER} layout={'horizontal'}/>
							</div>
						}
						classNameHeader={CLASS.EFFECT_CARD_TITLE}
						classNameChildren={CLASS.EFFECT_CARD_DESCRIPTION}
					>
						<Label
							id={ID.EFFECT_CARD_LABEL}
							className={CLASS.EFFECT_CARD_LABEL}
							tag={'label'}
						>
							{effectTxt}
						</Label>
					</Card>
				))}
			</div>}
		{pictureSelectModalOpen && <PictureSelectionModal
			id={ID.PICTURE_SELECTION_MODAL}
			availablePicture={props.allAvailablePictures}
			onClose={() => setPictureSelectModalOpen(false)}
			onSelectionButtonClick={
				(selectedPicture: string) => {
					props.setCharacterPicture?.(selectedPicture);
					setPictureSelectModalOpen(false);
				}
			}
		/>}
	</div>;

	function renderImage(
		ID: Record<'IMAGE' | 'IMAGE_CONTAINER' | 'IMAGE_BUTTON' | 'EFFECTS_CONTAINER' | 'EFFECT_CARD' | 'EFFECT_CARD_TITLE' | 'EFFECT_CARD_DESCRIPTION', string> & {
			ROOT: string;
		},
		CLASS: Record<'IMAGE' | 'IMAGE_CONTAINER' | 'IMAGE_BUTTON' | 'EFFECTS_CONTAINER' | 'EFFECT_CARD' | 'EFFECT_CARD_TITLE' | 'EFFECT_CARD_DESCRIPTION', string> & {
			ROOT: string;
		},
		props: IMainContainerProps) {
		if (props.isPreview) {
			return <>
				<img id={ID.IMAGE} className={CLASS.IMAGE} src={props.image} alt='character-portrait'/>
				{props.allAvailablePictures && props.allAvailablePictures.length > 1 && <ClickElement
					id={ID.IMAGE_BUTTON}
					className={CLASS.IMAGE_BUTTON}
					onClick={() => setPictureSelectModalOpen(true)}
				>
					<EditIcon/>
				</ClickElement>}
			</>;
		} else {
			return <img id={ID.IMAGE} className={CLASS.IMAGE} src={props.image} alt='character-portrait'/>;
		}
	}
}

MainContainer.getNames = getNames;

export default MainContainer;
