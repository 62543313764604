import React from 'react';
import {ArrowIcon, Card, Divider, IAccordionChildProps, Label, mergeClassNames} from 'iiomi-ui';
import { IWebComponentProps } from '../../interfaces/BaseComponentInterface';
import { AbilityCategory } from '../../pages/CharacterFeaturesPage/CharacterFeaturesPage';
import getNames from './getNames';
import useCss from './useCss';
import { FeatureCard } from '../FeatureCard';

export interface IFeatureBoxProps extends IAccordionChildProps, IWebComponentProps {
    title: string
    abilityCategory: AbilityCategory
}

function FeatureBox(props: IFeatureBoxProps) {

	const { ID, CLASS } = useCss(getNames(props.id));

	return <Card
		id={ID.ROOT}
		className={CLASS.ROOT}
		header={<div id={ID.FEATURE_BOX_HEADER} className={CLASS.FEATURE_BOX_HEADER}>
			<div id={ID.FEATURE_BOX_HEADER_TEXT_AND_ICON} className={CLASS.FEATURE_BOX_HEADER_TEXT_AND_ICON}>
				<Label id={ID.FEATURE_BOX_HEADER_TEXT} className={CLASS.FEATURE_BOX_HEADER_TEXT} tag={'h3'}>
					{props.title}
				</Label>
				<ArrowIcon className={mergeClassNames(CLASS.ARROW_ICON, {[CLASS.OPEN]: props.isOpen})} />
			</div>
			{props.isOpen && <Divider id={ID.DIVIDER} className={CLASS.DIVIDER} layout={'horizontal'}  />}
		</div>}
		onHeaderClick={props.onToggle}
		isOpen={props.isOpen}
		collapsible
	>
		<div id={ID.CATEGORY_BODY} className={CLASS.CATEGORY_BODY}>
			{Object.keys(props.abilityCategory).map((name, index) => (
				<FeatureCard
					id={ID.FEATURE_CARD}
					className={CLASS.FEATURE_CARD}
					key={index}
					name={props.abilityCategory[name].name}
					feature={props.abilityCategory[name]}
				/>
			))}
		</div>
	</Card>;
}

FeatureBox.getNames = getNames;

export default FeatureBox;