import { generateNames } from 'iiomi-ui';

export default (id: string) => generateNames(
	id,
	'MAIN_PAGE',
	'HEADER',
	'HOME_PAGE',
	'PLAY',
	'CHARACTER_OVERVIEW',
	'CHARACTER_CREATION',
	'CHARACTER_FEATURES',
	'LOG_IN',
	'SIGN_UP',
);