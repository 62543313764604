import React from 'react';
import { Label } from 'iiomi-ui';
import { IWebComponentProps } from '../../interfaces/BaseComponentInterface';
import getNames from './getNames';
import useCss from './useCss';
import { GoldIcon } from '../Icons';

export interface ICoinGoldDisplayProps extends IWebComponentProps {
    number: number;
}

function CoinGoldDisplay(props: ICoinGoldDisplayProps) {

	const { ID, CLASS } = useCss(getNames(props.id));

	return <div id={ID.ROOT} className={CLASS.ROOT}>
		<Label id={ID.NUMBER} className={CLASS.NUMBER} tag={'label'}>
			{props.number}
		</Label>
		<GoldIcon className={CLASS.COIN} />
	</div>;
}

export default CoinGoldDisplay;