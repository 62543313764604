import { generateNames } from 'iiomi-ui';

export default (id: string) => generateNames(
	id,
	'CHARACTER_VALUES',
	'TITLE',
	'STARTING_INFO_CONTAINER',
	'STARTING_INFO',
	'STARTING_HP',
	'HEART_HP_DISPLAY',
	'STARTING_GOLD',
	'COIN_GOLD_DISPLAY',
	'STARTING_HAND',
	'EQUIPMENT_LIMITS',
	'STARTING_EQUIPMENT_CONTAINER',
	'STARTING_EQUIPMENT',
	'STARTING_EQUIPMENT_TITLE',
	'ELEMENT_ICON',
	'EQUIPMENT_LIMITS_LIST',
	'LIMITS_LABEL',
	'FULL_INFO_CONTAINER',
);