import { generateNames } from 'iiomi-ui';

export default (id: string) => generateNames(
	id,
	'FEATURE_SLOTS',
	'TITLE',
	'SLOTS_CONTAINER',
	'SLOT',
	'SLOT_LABEL_AND_ICON',
	'SLOT_HEADER',
	'SLOT_LABEL',
	'UNLOCK_BUTTON',
	'SELECT_BUTTON',
	'DELETE_BUTTON',
	'FEATURE_SELECT_MODAL',
	'DIVIDER',
	'SLOT_CONTENT',
);