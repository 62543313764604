import { generateNames } from 'iiomi-ui';

export default (id: string) => generateNames(
	id,
	'FEATURE_CARD',
	'FEATURE_CARD_BODY',
	'FEATURE_TITLE',
	'EFFECT_TEXT',
	'REQUIRED_FEATURE_TEXT',
	'COST_TEXT'
);