import React from 'react';

export default function HeartIcon(props: { className?: string }) {
	return <svg
		className={props.className}
		viewBox='0 0 24 24'
	>
		<path
			d='M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={1}
			fill='#980000'
			stroke='black'
		/>
	</svg>;
}
