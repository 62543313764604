import React from 'react';
import {Container, Label} from 'iiomi-ui';
import {useForm} from 'react-hook-form';
import getNames from './getNames';
import useCss from './useCss';
import {Link, useNavigate} from 'react-router-dom';
import {useAuth} from '../../firebase';
import {ICredentials} from '../../interfaces/FirebaseInterfaces';
import {IWebComponentProps} from '../../interfaces/BaseComponentInterface';

export interface ILoginPageProps extends IWebComponentProps {
}

function LoginPage(props: ILoginPageProps) {

	const {ID, CLASS} = useCss(getNames(props.id));
	const methods = useForm<ICredentials>({mode: 'onBlur'});

	const { signIn } = useAuth();
	const navigate = useNavigate();

	const {
		register,
		handleSubmit,
		formState: {errors}
	} = methods;

	const onSubmit = async (data: ICredentials) => {
		try {
			signIn({email: data.email, password: data.password});
			navigate('/');
		} catch (error) {
			console.error(error);
		}
	};

	return <Container id={ID.ROOT} className={CLASS.ROOT}>
		<Label id={ID.TITLE} className={CLASS.TITLE} tag='h2'>
			Log In
		</Label>
		<form onSubmit={handleSubmit(onSubmit)} className={CLASS.FORM}>
			<div>
				<div>
					<label>
						Email
					</label>
				</div>
				<input
					type='email'
					{...register('email', {required: 'Email is required'})}
				/>
				{errors.email && <p>{errors.email.message}</p>}
			</div>
			<div>
				<div>
					<label>
						Password
					</label>
				</div>

				<input
					type='password'
					{...register('password', {required: 'Password is required'})}
				/>
				{errors.password && <p>{errors.password.message}</p>}
			</div>
			<div>
				<button id={ID.SUBMIT_BUTTON} className={CLASS.SUBMIT_BUTTON}> Log In </button>
			</div>
		</form>
		<Label id={ID.FORGOT_PASSWORD} className={CLASS.FORGOT_PASSWORD} tag='label'>
			Forgot Password?
		</Label>
		<Link to='/signUp'><Label id={ID.SIGN_UP} className={CLASS.SIGN_UP} tag='label'>Sign Up</Label></Link>
	</Container>;
}

export default LoginPage;