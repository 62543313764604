import { css } from 'iiomi-ui';
import getNames from './getNames';

const { CLASS } = getNames('not_relevant');

export default css({
	[CLASS.ROOT]: {
		display: 'flex',
		flexDirection: 'column',
		rowGap: '1em',
		width: '100%'
	},
});