import { generateNames } from 'iiomi-ui';

export default (id: string) => generateNames(
	id,
	'FEATURE_SELECTION_MODAL',
	'WRAPPER',
	'MODAL_BODY',
	'LOADED',
	'CATEGORY',
	'CATEGORY_TITLE',
	'FEATURES',
	'FEATURE',
);