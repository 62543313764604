import { css } from 'iiomi-ui';
import getNames from './getNames';

const { CLASS } = getNames('not_relevant');

export default css({
	[CLASS.ROOT]: {
		[css.descendant(CLASS.CARD_BODY)]: {
			display: 'flex',
			flexDirection: 'row',
			flexWrap: 'wrap',
			[css.descendant(CLASS.FEATURE_SLOTS)]: {
				flex: '1 100%',
			},
			[css.descendant(CLASS.EQUPMENT_PACK)]: {
				display: 'flex',
				flexDirection: 'column',
				flex: '1 100%',
			},
			[css.descendant(CLASS.FOOTER)]: {
				flex: '1 100%',
				display: 'flex',
				paddingTop: '0.5em',
			},
			[css.descendant(CLASS.QUOTE)]: {
				display: 'flex',
				padding: '0.5em 1em',
			},
		},
		[css.MEDIA_QUERIES.minWidth(600)]: {
			[css.descendant(CLASS.CARD_BODY)]: {
				[css.descendant(CLASS.FEATURE_SLOTS, CLASS.FEATURE_SLOTS)]: {
					flex: '1 auto',
					maxWidth: '25em'
				},
				[css.descendant(CLASS.EQUPMENT_PACK, CLASS.EQUPMENT_PACK)]: {
					flex: '1 auto',
					maxWidth: '25em'
				},
			},
		},
		[css.MEDIA_QUERIES.minWidth(800)]: {
			[css.descendant(CLASS.CARD_BODY)]: {
				[css.descendant(CLASS.FEATURE_SLOTS)]: {
					order: '1',
				},
				[css.descendant(CLASS.MAIN_CONTAINER, CLASS.MAIN_CONTAINER)]: {
					flex: '3 0em',
					order: '2',
				},
				[css.descendant(CLASS.EQUPMENT_PACK)]: {
					order: '3',
				},
				[css.descendant(CLASS.FOOTER)]: {
					order: '4',
				},
			},
		},
	},
});
