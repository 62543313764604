import React from 'react';
import {IWebComponentProps} from '../../../interfaces/BaseComponentInterface';
import useCss from './useCss';
import getNames from './getNames';
import {Button, Label, mergeClassNames} from 'iiomi-ui';
import {getElementIcon} from '../../../helpers/firebaseHelpers';
import {HeartHPDisplay} from '../../HeartHPDisplay';
import {CoinGoldDisplay} from '../../CoinGoldDisplay';

export interface ICharacterValuesProps extends IWebComponentProps {
    startingEqPack: any;
    startingHP: any;
    startingGold: any;
    startingHand: any;
    equipmentLimits: any;
    isPreview?: boolean;
    currentExp?: number;
    totalExp?: number;
    items?: any;
}

function CharacterValues(props: ICharacterValuesProps) {

	const { ID, CLASS } = useCss(getNames(props.id));
	const [iconUrls, setIconUrls] = React.useState<string[]>([]);

	React.useEffect(() => {
		const fetchIconUrls = async () => {
			const urls = await Promise.all(props.startingEqPack.split(',').map(async (item: string) => {
				return await getElementIcon(item.trim());
			}));
			setIconUrls(urls.filter(url => url !== null));
		};
		fetchIconUrls();
	}, [props.startingEqPack]);

	return <div id={ID.ROOT} className={mergeClassNames(CLASS.ROOT, props.className)}>
		<Label id={ID.TITLE} className={CLASS.TITLE} tag={'h4'}>Info:</Label>
		<div id={ID.STARTING_INFO_CONTAINER} className={CLASS.STARTING_INFO_CONTAINER}>
			<div id={ID.STARTING_INFO} className={CLASS.STARTING_INFO}>
				<Label id={ID.STARTING_HP} className={CLASS.STARTING_HP} tag={'label'}>
					{`Starting Hit Points:`}
				</Label>
				<HeartHPDisplay id={ID.HEART_HP_DISPLAY} className={CLASS.HEART_HP_DISPLAY} number={props.startingHP} />
				<Label id={ID.STARTING_GOLD} className={CLASS.STARTING_GOLD} tag={'label'}>
					{`Starting Gold: ${props.startingGold}`}
				</Label>
				<CoinGoldDisplay id={ID.COIN_GOLD_DISPLAY} className={CLASS.COIN_GOLD_DISPLAY} number={props.startingGold} />
				<Label id={ID.STARTING_HAND} className={CLASS.STARTING_HAND} tag={'label'}>
					{`Starting Hand Size: ${props.startingHand} Cards`}
				</Label>
			</div>
			<Label id={ID.STARTING_EQUIPMENT_TITLE} className={CLASS.STARTING_EQUIPMENT_TITLE} tag={'label'}>
				{`Starting Equipment: `}
			</Label>
			<div id={ID.STARTING_EQUIPMENT_CONTAINER} className={CLASS.STARTING_EQUIPMENT_CONTAINER}>
				{iconUrls.map((url, index) => (
					<img id={ID.ELEMENT_ICON} className={CLASS.ELEMENT_ICON} src={url} alt='element-icon' key={index} />
				))}
			</div>
			<Label id={ID.EQUIPMENT_LIMITS} className={CLASS.EQUIPMENT_LIMITS} tag={'h4'}>
                Equipment Limits:
			</Label>
			<div id={ID.EQUIPMENT_LIMITS_LIST} className={CLASS.EQUIPMENT_LIMITS_LIST}>
				{Object.entries(props.equipmentLimits).map(([key, value], index) => {
					return <Label id={ID.LIMITS_LABEL} className={CLASS.LIMITS_LABEL} key={index} tag={'label'}>
						{`${key}: ${value}`}
					</Label>;
				})}
			</div>
		</div>
		{!props.isPreview && <div id={ID.FULL_INFO_CONTAINER} className={CLASS.FULL_INFO_CONTAINER}>
			<Label id={ID.STARTING_HP} className={CLASS.STARTING_HP} tag={'label'}>
				{`Total Experience: ${props.totalExp}`}
			</Label>
			<Label id={ID.STARTING_HP} className={CLASS.STARTING_HP} tag={'label'}>
				{`Current Experience: ${props.currentExp}`}
			</Label>
			{props.items && <Button id={''} variant={'secondary'} onClick={() => alert('Not implemented yet.')}> View Items </Button>}
		</div>}
	</div>;

}

export default CharacterValues;