import { generateNames } from 'iiomi-ui';

export default (id: string) => generateNames(
	id,
	'FEATURE_BOX',
	'FEATURE_BOX_HEADER',
	'FEATURE_BOX_HEADER_TEXT_AND_ICON',
	'FEATURE_BOX_HEADER_TEXT',
	'ARROW_ICON',
	'OPEN',
	'DIVIDER',
	'CATEGORY_BODY',
	'FEATURE_CARD'
);