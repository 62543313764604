import React, {useContext, useEffect, useState} from 'react';
import {onAuthStateChanged, User} from 'firebase/auth';
import {auth, fireStore} from '../../firebase/Firebase';
import {doc, setDoc} from 'firebase/firestore';
import {ICredentials} from '../../interfaces/FirebaseInterfaces';
import {firebaseSignIn, firebaseSignOut, firebaseSignUp} from './AuthService';

interface IAuthContext {
	user: User | null;
	loading: boolean;
	signUp: (credentials: ICredentials) => void;
	signIn: (credentials: ICredentials) => void;
	signOut: () => void;
}

export const AuthContext = React.createContext<IAuthContext>({
	user: auth.currentUser,
	loading: false,
	signUp: () => {},
	signIn: () => {},
	signOut: () => {}
});

export const useAuth = () => useContext(AuthContext);

const AuthContextProvider = ({children}: { children: React.ReactNode }) => {

	const [currentUser, setCurrentUser] = useState<User | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isAuthLoading,  setIsAuthLoading] =  useState<boolean>(true);

	const signUp = (credentials: ICredentials) => {
		setIsLoading(true);
		firebaseSignUp(credentials)
			.then(async signUpResult => {
				const {user} = signUpResult;
				if(user) {
					setCurrentUser(user);
					await setDoc(doc(fireStore, 'Users', user.uid), {
						uid: user.uid,
						email: user.email,
						maxCharacters: 3
					});
				} else {
					alert('Error: User not found');
					console.log(user);
				}
				setIsLoading(false);
			}).catch(error => {
				if(error.code === 'auth/email-already-in-use') {
					alert('Email already in use');
				} else {
					alert('Error: ' + error.message);
				}
				setIsLoading(false);
			});
	};

	const signIn = async (credentials: ICredentials) => {
		setIsLoading(true);
		firebaseSignIn(credentials).then(signInResult => {
			const {user} = signInResult;
			if(user) {
				setCurrentUser(user);
			} else {
				alert('Error: User not found');
				console.log(user);
			}
			setIsLoading(false);
		}).catch(error => {
			if  (error.code  ===  'auth/wrong-password') {
				alert('Wrong password');
			} else {
				alert('Error: ' + error.message);
			}
			setIsLoading(false);
		});
	};

	const signOut = async () => {
		setIsLoading(true);
		try {
			await firebaseSignOut();
			setCurrentUser(null);
		} catch (error) {
			alert('Error: ' + error);
			setIsLoading(false);
		}
	};

	const authContextValues: IAuthContext = {
		user: currentUser,
		loading: isLoading,
		signUp,
		signIn,
		signOut

	};

	useEffect(() => {
		return onAuthStateChanged(auth, user => {
			setCurrentUser(user);
			setIsAuthLoading(false);
		});
	}, []);

	if(isAuthLoading) {
		return <div>Loading</div>;
	}

	return <AuthContext.Provider value={authContextValues}>
		{children}
	</AuthContext.Provider>;
};

export default AuthContextProvider;
