import React from 'react';
import { IWebComponentProps } from '../../interfaces/BaseComponentInterface';
import { FeaturesData } from '../../pages/CharacterFeaturesPage/CharacterFeaturesPage';
import getNames from './getNames';
import useCss from './useCss';
import { Accordion } from 'iiomi-ui';
import FeatureBox from '../FeatureBox/FeatureBox';

export interface IFeatureAccordionProps extends IWebComponentProps {
    featuresData: FeaturesData;
}

function FeaturesAccordion(props: IFeatureAccordionProps) {

	const { ID, CLASS } = useCss(getNames(props.id));

	const sortOrder = ['Background', 'Common', 'Arcane', 'Deception', 'Devotion', 'Martial', 'magicOriginSlot', 'patronSlot'];
	const sortedData: FeaturesData = {};

	sortOrder.forEach(category => {
		if (Object.prototype.hasOwnProperty.call(props.featuresData, category)) {
			sortedData[category] = props.featuresData[category];
		}
	});

	return <Accordion
		id={ID.ROOT}
		className={CLASS.ROOT}
		defaultOpenChildIndex={0}
	>
		{getChildProps => Object.keys(sortedData).map((category, index) => (
			<FeatureBox
				{...getChildProps(index)}
				key={index}
				id={ID.CATEGORY}
				className={CLASS.CATEGORY}
				title={category}
				abilityCategory={props.featuresData[category]}
			/>
		))}
	</Accordion>;
}

FeaturesAccordion.getNames = getNames;

export default FeaturesAccordion;