export enum Locale {
	en = 'en',
}

export interface II18nData<T extends Record<string, any>>{
	[Locale.en]: T;
}

export class I18n<T extends Record<string, any>> {
	private readonly i18nData: II18nData<T>;
	private locale: Locale;

	constructor(i18nData: II18nData<T>, locale: Locale = Locale.en) {
		this.i18nData = i18nData;
		this.locale = locale;
	}

	setLocale(locale: Locale) {
		this.locale = locale;
	}

	get(): T {
		const currentLocale = this.i18nData[this.locale];

		if (!currentLocale) {
			throw new Error(`Locale ${this.locale} is not supported.`);
		}

		return currentLocale;
	}
}