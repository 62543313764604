import { css } from 'iiomi-ui';
import getNames from './getNames';

const { CLASS } = getNames('not_relevant');

export default css({
	[CLASS.ROOT]: {
		display: 'flex',
		flexDirection: 'column',
		rowGap: '0.5em',
		padding: '0.5em 1em',
		[css.descendant(CLASS.STARTING_INFO_CONTAINER)]: {
			display: 'flex',
			flexDirection: 'column',
			rowGap: '0.5em',
			[css.descendant(CLASS.STARTING_INFO)]: {
				display: 'flex',
				flexDirection: 'column',
				gap: '0.5em',
			},
			[css.descendant(CLASS.EQUIPMENT_LIMITS_LIST)]: {
				display: 'flex',
				flexDirection: 'column',
				gap: '0.5em'
			},
		},
		[css.descendant(CLASS.STARTING_EQUIPMENT_CONTAINER)]: {
			[css.descendant(CLASS.ELEMENT_ICON)]: {
				width: '2.75em',
			},
		},
		[css.descendant(CLASS.FULL_INFO_CONTAINER)]: {
			display: 'flex',
			flexDirection: 'column',
			gap: '0.5em'
		},
	},
});
