import React from 'react';

export default function EditIcon(props: { className?: string }) {
	return <svg
		className={props.className}
		viewBox='0 0 24 24'
	>
		<path
			d='M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={2}
			fill='none'
			stroke='black'
		/>
		<path
			d='M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={2}
			stroke='black'
		/>
	</svg>;
}
