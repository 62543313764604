import { css } from 'iiomi-ui';
import getNames from './getNames';

const { CLASS } = getNames('not_relevant');

export default css({
	[CLASS.ROOT]: {
		[css.descendant(CLASS.FEATURE_BOX_HEADER)]: {
			display: 'flex',
			flexDirection: 'column',
			flexWrap: 'nowrap',
			alignItems: 'center',
			padding: '0.5em 1em',
			[css.descendant(CLASS.FEATURE_BOX_HEADER_TEXT_AND_ICON)]: {
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'center',
				flexWrap: 'nowrap',
				alignItems: 'center',
				width: '100%',
				[css.descendant(CLASS.FEATURE_BOX_HEADER_TEXT)]: {
					flex: '1 1 0',
					textAlign: 'center',
				},
				[css.descendant(CLASS.ARROW_ICON)]: {
					margin: 'auto',
					cursor: 'pointer',
					transition: 'all 0.5s ease',
					width: '2em',
					[css.modifier(CLASS.OPEN)]: {
						transform: 'rotateX(180deg)',
					},
				},
			},
			[css.descendant(CLASS.DIVIDER)]: {
				margin: '0.5em 0',
			}
		},
		[css.descendant(CLASS.CATEGORY_BODY)]: {
			display: 'flex',
			flexWrap: 'wrap',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'space-evenly',
			gap: '0.75em',
			padding: '1em',
			[css.descendant(CLASS.FEATURE_CARD)]: {
				flex: '0 1 auto',
			}
		},
	},
});