import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getDatabase } from 'firebase/database';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';

const config = {
	apiKey: 'AIzaSyAKT6Oo3Y64gWbTKz0C5qWOxc0ZXz4Lfr4',
	authDomain: 'dragonfire-7bfa6.firebaseapp.com',
	databaseURL: 'https://dragonfire-7bfa6.firebaseio.com',
	projectId: 'dragonfire-7bfa6',
	storageBucket: 'dragonfire-7bfa6.appspot.com',
	messagingSenderId: '936682577083',
	appId: '1:936682577083:web:668e0c28d1b8fdbf679d9e'
};

const firebaseApp = initializeApp(config);
const db = getDatabase(firebaseApp);
const fireStore = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);
const fireStorage = getStorage(firebaseApp);

export { db, fireStore, fireStorage, auth };
