import React from 'react';
import {useAuth} from '../../firebase';
import {IWebComponentProps} from '../../interfaces/BaseComponentInterface';
import getNames from './getNames';
import useCss from './useCss';
import {Container, Label} from 'iiomi-ui';
import {CharacterOperations} from '../CharacterOperations';
import {MyCharactersAccordion} from '../MyCharactersAccordion';
import {ICharacterInFirebase} from '../../interfaces/FirebaseInterfaces';
import {getCharacters, getMaxAmountOfCharacters} from '../../helpers/firebaseHelpers';
import {getI18n} from '../../i18n';

interface IOverviewState {
	loaded: boolean;
	error: boolean;
	characters: ICharacterInFirebase[];
	maxAmountOfCharacters: number;
}

export interface ICharactersOverviewProps extends IWebComponentProps {
}

function CharactersOverview(props: ICharactersOverviewProps) {
	const {ID, CLASS} = useCss(getNames(props.id));
	const {user} = useAuth();

	const [overviewState, setOverviewState] = React.useState<IOverviewState>({
		loaded: false,
		error: false,
		characters: [],
		maxAmountOfCharacters: 0
	});

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const [maxCharactersData, charactersData] = await Promise.all([
					getMaxAmountOfCharacters(user),
					getCharacters(user)
				]);
				setOverviewState({
					loaded: true,
					error: false,
					characters: charactersData,
					maxAmountOfCharacters: maxCharactersData
				});
			} catch (error) {
				console.error(error);
				setOverviewState((prevState) => ({...prevState, error: true, loaded: true}));
			}
		};
		fetchData();
	}, [user]);

	const { loaded, error, characters, maxAmountOfCharacters } = overviewState;

	const i18n = getI18n();
	return <Container id={ID.ROOT} className={CLASS.ROOT}>
		{!loaded && <Label id={ID.TITLE} className={CLASS.TITLE} tag='h1'>{i18n.LOADING}</Label>}
		{error && <Label id={ID.TITLE} className={CLASS.TITLE} tag='h1'>{i18n.ERROR.LOADING_CHARACTERS}</Label>}
		{loaded && !error && <>
			<Label id={ID.TITLE} className={CLASS.TITLE} tag='h1'>{i18n.CHARACTER_OVERVIEW_HEADER(characters.length, maxAmountOfCharacters)}</Label>
			<MyCharactersAccordion
				id={ID.CHARACTERS_ACCORDION}
				className={CLASS.CHARACTERS_ACCORDION}
				amount={maxAmountOfCharacters}
				characters={characters}
			/>
			<CharacterOperations id={ID.OPERATIONS} className={CLASS.OPERATIONS}/>
		</>}
	</Container>;
}

CharactersOverview.getNames = getNames;

export default CharactersOverview;