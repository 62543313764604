import React from 'react';
import ReactDOM from 'react-dom/client';
import AuthContextProvider from './helpers/authHelpers/AuthContext';
import { MainPage } from './pages/MainPage';

const mainPage = ReactDOM.createRoot(
    document.getElementById('mainPage') as HTMLElement
);

mainPage.render(
	<AuthContextProvider>
		<MainPage id={'page'} />
	</AuthContextProvider>
);