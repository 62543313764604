import {
	browserLocalPersistence,
	createUserWithEmailAndPassword,
	setPersistence,
	signInWithEmailAndPassword, signOut
} from 'firebase/auth';
import {auth} from '../../firebase/Firebase';
import {ICredentials} from '../../interfaces/FirebaseInterfaces';

setPersistence(auth, browserLocalPersistence);

export const firebaseSignUp = async ({email, password}: ICredentials) => {
	return await createUserWithEmailAndPassword(auth, email, password);
};

export const firebaseSignIn = async ({email, password}: ICredentials) => {
	return await signInWithEmailAndPassword(auth, email, password);
};

export const firebaseSignOut = async () => {
	return await signOut(auth);
};