import React from 'react';
import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';
import {NavigationBar, ProtectedRoute} from '../../components';
import getNames from './getNames';
import { IWebComponentProps } from '../../interfaces/BaseComponentInterface';
import { SignUpPage } from '../SignUpPage';
import { LogInPage } from '../LogInPage';
import { HomePage } from '../HomePage';
import { CharacterFeaturesPage } from '../CharacterFeaturesPage';
import { CharactersOverviewPage } from '../CharactersOverviewPage';
import { CharacterCreationPage } from '../CharacterCreationPage';
import useCss from './useCss';

interface IMainPageProps extends IWebComponentProps {
}

function MainPage(props: IMainPageProps) {
	const { ID, CLASS } = useCss(getNames(props.id));

	const router = createBrowserRouter([
		{
			id: ID.ROOT,
			element: <HeaderLayout id={ID.HEADER} className={CLASS.HEADER}/>,
			children: [
				{
					path: '/',
					element: <HomePage id={ID.HOME_PAGE} className={CLASS.HOME_PAGE}/>,
				},
				{
					path: '/foo',
					element: <div>foo</div>,
				},
				{
					path: '/myCharacters',
					element: <ProtectedRoute>
						<CharactersOverviewPage id={ID.CHARACTER_OVERVIEW} className={CLASS.CHARACTER_OVERVIEW}/>
					</ProtectedRoute>,
				},
				{
					path: '/cCreation',
					element: <ProtectedRoute>
						<CharacterCreationPage id={ID.CHARACTER_CREATION} className={CLASS.CHARACTER_CREATION}/>
					</ProtectedRoute>,
				},
				{
					path: '/cFeatures',
					element: <ProtectedRoute>
						<CharacterFeaturesPage id={ID.CHARACTER_FEATURES} className={CLASS.CHARACTER_FEATURES}/>
					</ProtectedRoute>,
				},
				{
					path: '/login',
					element: <LogInPage id={ID.LOG_IN} className={CLASS.LOG_IN} />,
				},
				{
					path: '/signUp',
					element: <SignUpPage id={ID.SIGN_UP} className={CLASS.SIGN_UP}/>,
				}
			],
		},
	]);

	return (
		<RouterProvider router={router} />
	);
}

function HeaderLayout(props: {id: string, className?: string}) {
	return <>
		<header>
			<NavigationBar id={props.id} />
		</header>
		<Outlet />
	</>;
}

MainPage.getNames = getNames;

export default MainPage;
