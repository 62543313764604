import {ICharacterInFirebase, IFeatureSlot, IRaceConfiguration} from '../interfaces/FirebaseInterfaces';
import {doc, updateDoc} from 'firebase/firestore';
import {fireStore} from '../firebase';

export const createCharacterForPreview = (selectedCharacter: IRaceConfiguration, characterName: string, selectedCharacterImage: string, selectedClass: string, selectedRace: string): ICharacterInFirebase => {
	return {
		name: characterName,
		allFeatureSlots: [],
		imageUrl: selectedCharacterImage,
		totalExp: 0,
		class: selectedClass,
		exp: 0,
		race: selectedRace,
		itemPack: [],
		level: 1,
		backgroundSelected: false,
		...selectedCharacter,
	};

};

export async function updateCharacterExperience(amount: number, user: any, characterName: string, characterEXP: number) {
	const newCharacterEXP = characterEXP + amount;
	try {
		await updateDoc(doc(fireStore, 'Users', `${user.uid}`, 'Characters', `${characterName}`), {
			exp: newCharacterEXP
		});
		console.log('Character EXP updated successfully');
	} catch (error) {
		console.error('Error updating character document:', error);
	}

}

export async function unlockFeatureSlot(slotIndex: number, user: any, characterName?: string, allFeatureSlots?: IFeatureSlot[], characterEXP?: number) {

	if (!characterName || !allFeatureSlots || slotIndex <= 0 || slotIndex >= allFeatureSlots.length || characterEXP == undefined) {
		console.error('Invalid parameters provided.');
		return;
	}

	const slot = allFeatureSlots[slotIndex];

	const allPreviousUnlocked = allFeatureSlots.slice(0, slotIndex).every(slot => !slot.locked);

	if (!allPreviousUnlocked) {
		console.error('Cannot unlock current slot as all previous slots are not unlocked');
		return;
	}

	if (slot.cost && slot.cost <= characterEXP) {
		allFeatureSlots[slotIndex] = {...slot, locked: false};
		try {
			await updateDoc(doc(fireStore, 'Users', `${user.uid}`, 'Characters', `${characterName}`), {
				allFeatureSlots,
			});
			await updateCharacterExperience(-slot.cost, user, characterName, characterEXP);
			console.log('Feature slot unlocked successfully');
		} catch (error) {
			console.error('Error updating character document:', error);
		}
	} else {
		console.warn('Character does not have enough EXP to unlock the slot');
		alert('You do not have enough EXP to unlock this slot');
	}
}