import React from 'react';
import { Ability } from '../../pages/CharacterFeaturesPage/CharacterFeaturesPage';
import { Card, Label, mergeClassNames } from 'iiomi-ui';
import getNames from './getNames';
import useCss from './useCss';
import {IWebComponentProps} from '../../interfaces/BaseComponentInterface';

export interface IFeatureCardProps extends IWebComponentProps {
    name: string;
    feature: Ability
}

function FeatureCard(props: IFeatureCardProps) {

	const { ID, CLASS } = useCss(getNames(props.id));

	return <Card
		id={ID.ROOT}
		className={mergeClassNames(CLASS.ROOT, props.className)}
		header={props.name}
		classNameChildren={CLASS.FEATURE_CARD_BODY}
		classNameHeader={CLASS.FEATURE_TITLE}
	>
		<Label id={ID.EFFECT_TEXT} className={CLASS.EFFECT_TEXT}> {props.feature.effectTxt} </Label>
		{props.feature.feature && <p>Required Feature:{props.feature.feature}</p>}
		{props.feature.race && <p>Required Race:{props.feature.race}</p>}
		<Label id={ID.COST_TEXT} className={CLASS.COST_TEXT}> {`${props.feature.cost} XP`} </Label>
	</Card>;
}

export default FeatureCard;