import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AppBar, Button, Divider, Label } from 'iiomi-ui';
import useCss from './useCss';
import getNames from './getNames';
import { IWebComponentProps } from '../../interfaces/BaseComponentInterface';
import { useAuth } from '../../firebase';

export interface INavigationBarProps extends IWebComponentProps { }

function NavigationBar(props: INavigationBarProps) {

	const { ID, CLASS } = useCss(getNames(props.id));

	const { user, signOut } = useAuth();
	const navigate = useNavigate();
	const handleLogin = () => {
		navigate('/Login');
	};

	const isUserPresent = user ? user.uid ? true : false : false;

	const handleLogout = async () => {
		try {
			signOut();
			navigate('/');
		} catch (error) {
			console.error(error);
		}
	};

	return <AppBar id={ID.ROOT} className={CLASS.ROOT} position='relative'>
		<Link to='/' className={CLASS.LINK}><Label id='label-test' tag='h1'>Dragonfire</Label></Link>
		<div className={CLASS.LINK_BOX}>
			{isUserPresent ? <>
				<Divider id={ID.DIVIDER} className={CLASS.DIVIDER} layout={'vertical'} />
				<Link to='/foo' className={CLASS.LINK}><Label id='label-test' tag='h3'>Play</Label></Link>
				<Link to='/myCharacters' className={CLASS.LINK}><Label id='label-test' tag='h3'>My Characters</Label></Link>
				<Link to='/cFeatures' className={CLASS.LINK}><Label id='label-test' tag='h3'>Character Features</Label></Link>
			</>
				: undefined}
		</div>
		<Button id={ID.LOGIN_BUTTON} onClick={isUserPresent ? handleLogout : handleLogin} >{`${isUserPresent ? 'Logout' : 'Login'}`}</Button>
	</AppBar>;
}

export default NavigationBar;
