import { css } from 'iiomi-ui';
import getNames from './getNames';

const { CLASS } = getNames('not_relevant');

export default css({
	[CLASS.ROOT]: {
		display: 'flex',
		height: '100%',
		padding: '2em',
		flexDirection: 'column',
		alignItems: 'center',
		rowGap: '1em',
		[css.descendant(CLASS.MAIN_CONTAINER)]: {
			display: 'flex',
			alignItems: 'center',
			width: '100%',
			justifyContent: 'center',
			columnGap: '10em',
			[css.descendant(CLASS.CHARACTER_NAME_INPUT)]: {
				width: '20%',
			},
			[css.descendant(CLASS.CLASSES_CONTAINER)]: {
				display: 'flex',
				flexWrap: 'wrap',
				flexDirection: 'row',
				gap: '0.5em',
				justifyContent: 'space-between',
				[css.descendant(CLASS.CLASS_BUTTON)]: {
					[css.modifier(CLASS.MARTIAL)]: {
						background: 'radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(61,72,83,1) 50%, rgba(106,106,106,1) 100%);',
					},
					[css.modifier(CLASS.DECEPTION)]: {
						background: 'radial-gradient(circle, rgba(61,46,44,1) 0%, rgba(88,52,48,1) 50%, rgba(182,70,56,1) 100%);',
					},
					[css.modifier(CLASS.DEVOTION)]: {
						background: 'radial-gradient(circle, rgba(40,48,40,1) 0%, rgba(55,73,56,1) 50%, rgba(125,168,96,1) 100%)',
					},
					[css.modifier(CLASS.ARCANE)]: {
						background: 'radial-gradient(circle, rgba(42,51,61,1) 0%, rgba(68,89,113,1) 50%, rgba(29,100,185,1) 100%);',
					},
				},
			},
			/*[css.descendant(CLASS.RACES_CONTAINER)]: {
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'row',
                gap: '0.5em',
                justifyContent: 'space-between',
            },*/
		},
		[css.descendant(CLASS.PREVIEW_CONTAINER)]: {
			alignSelf: 'stretch',
			display: 'flex',
			gap: '0.5em',
			flexDirection: 'column',
		}
	},
});