import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../firebase';
import React from 'react';

export interface IProtectedRouteProps {
    children: React.ReactNode;
}

function ProtectedRoute(props: IProtectedRouteProps) {

	const navigate = useNavigate();
	const { user } = useAuth();

	const loggedIn = !!user?.uid;

	React.useEffect(() => {
		if (!loggedIn) {
			navigate('/Login');
		}
	}, [loggedIn, user, navigate]);

	return <>
		{loggedIn ? props.children : null}
	</>;
}

export default ProtectedRoute;
