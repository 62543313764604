import { generateNames } from 'iiomi-ui';

export default (id: string) => generateNames(
	id,
	'CHARACTER_CARD',
	'HEADER',
	'ARCHETYPE',
	'NAME',
	'CLASS',
	'RACE',
	'CARD_BODY',
	'FEATURE_SLOTS',
	'MAIN_CONTAINER',
	'EQUPMENT_PACK',
	'FOOTER',
	'SIDE',
	'STARTING_INFO',
	'QUOTE',
);