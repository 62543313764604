import React from 'react';
import {useAuth} from '../../firebase';
import {IWebComponentProps} from '../../interfaces/BaseComponentInterface';
import getNames from './getNames';
import useCss from './useCss';
import {ICharacterInFirebase, IDatabaseClassList} from '../../interfaces/FirebaseInterfaces';
import {useNavigate} from 'react-router-dom';
import {Button, Container, Input, Label, Modal, mergeClassNames} from 'iiomi-ui';
import {ClassSelectionModal} from './ClassSelectionModal';
import {RaceSelectionModal} from './RaceSelectionModal';
import {getCharacterPictureUrl, getClasses, saveCharacter} from '../../helpers/firebaseHelpers';
import {createCharacterForPreview} from '../../helpers/characterHelpers';
import {CharacterCard} from '../CharacterCard';
import {getI18n} from '../../i18n';

export interface ICharacterCreationProps extends IWebComponentProps {
}

function CharacterCreation(props: ICharacterCreationProps) {

	const {ID, CLASS} = useCss(getNames(props.id));
	const {user} = useAuth();
	const navigate = useNavigate();

	const [loaded, setLoaded] = React.useState<boolean>(false);
	const [error, setError] = React.useState<boolean>(false);
	const [availableClasses, setAvailableClasses] = React.useState<IDatabaseClassList>({});
	const [avaliablePictures, setAvaliablePictures] = React.useState<string[]>([]);

	const [characterName, setCharacterName] = React.useState<string>('');
	const [selectedArchetype, setSelectedArchetype] = React.useState<string>('');
	const [selectedClass, setSelectedClass] = React.useState<string>('');
	const [selectedRace, setSelectedRace] = React.useState<string>('');
	const [selectedCharacterImage, setSelectedCharacterImage] = React.useState<string>('');

	const [classSelectionOpen, setClassSelectionOpen] = React.useState<boolean>(false);
	const [raceSelectionOpen, setRaceSelectionOpen] = React.useState<boolean>(false);
	const [confirmationOpen, setConfirmationOpen] = React.useState<boolean>(false);

	const [characterForCard, setCharacterForCard] = React.useState<ICharacterInFirebase>();

	const i18n = getI18n();
	const shouldLoadPreview = selectedArchetype && selectedClass && selectedRace && characterName;

	React.useEffect(() => {
		getClasses()
			.then(classes => {
				setAvailableClasses(classes);
			})
			.catch(error => {
				console.error(error);
				setError(true);
			})
			.finally(() => setLoaded(true));
	}, []);

	React.useEffect(() => {
		getCharacterPictureUrl(selectedRace, selectedClass)
			.then(pic => {
				setAvaliablePictures(pic);
				setSelectedCharacterImage(pic[0]);
			})
			.catch(error => {
				console.error(error);
				setError(true);
			});
	}, [selectedRace, selectedClass]);

	React.useEffect(() => {
		if (selectedClass && selectedRace && characterName) {
			const raceConfig = availableClasses[selectedClass]?.[selectedRace];
			if (raceConfig) {
				setCharacterForCard(createCharacterForPreview(raceConfig, characterName, selectedCharacterImage, selectedClass, selectedRace));
			}
		}
	}, [selectedRace, selectedClass, characterName, selectedCharacterImage, availableClasses]);

	return <Container id={ID.ROOT} className={mergeClassNames(CLASS.ROOT, props.className)}>
		{!loaded && <Label id={ID.TITLE} className={CLASS.TITLE} tag='h1'>{i18n.LOADING}</Label>}
		{loaded && error && <Label id={ID.TITLE} className={CLASS.TITLE} tag='h1'> Error in loading.</Label>}
		{loaded && !error && <>
			<Label id={ID.TITLE} className={CLASS.TITLE} tag='h1'>
                Create a character!
			</Label>
			<div id={ID.MAIN_CONTAINER} className={CLASS.MAIN_CONTAINER}>
				<Input
					id={ID.CHARACTER_NAME_INPUT}
					className={CLASS.CHARACTER_NAME_INPUT}
					label={'Character Name'}
					onChange={characterName => setCharacterName(characterName)}
					value={characterName}
				/>
				<Button
					id={ID.CHARACTER_CLASS_BUTTON}
					className={CLASS.CHARACTER_CLASS_BUTTON}
					onClick={() => setClassSelectionOpen(true)}
				>
					{`${selectedClass ? selectedClass : 'Select a class'}`}
				</Button>
				<Button
					id={ID.CHARACTER_RACE_BUTTON}
					className={CLASS.CHARACTER_RACE_BUTTON}
					disabled={selectedClass == null || selectedArchetype == null}
					onClick={() => setRaceSelectionOpen(true)}
				>
					{`${selectedRace ? selectedRace : 'Select a race'}`}
				</Button>
				{classSelectionOpen && <ClassSelectionModal
					id={ID.MODAL}
					className={CLASS.MODAL}
					onClose={() => setClassSelectionOpen(false)}
					onSelectionButtonClick={(archetype, selectedClass) => {
						setSelectedArchetype(archetype);
						setSelectedClass(selectedClass);
						setSelectedRace('');
						setClassSelectionOpen(false);
					}}
					availableClasses={availableClasses}
				/>}
				{raceSelectionOpen && <RaceSelectionModal
					id={ID.MODAL}
					className={CLASS.MODAL}
					onClose={() => setRaceSelectionOpen(false)}
					onSelectionButtonClick={(selectedRace) => {
						setSelectedRace(selectedRace);
						setRaceSelectionOpen(false);
					}}
					avaliableRaces={availableClasses[selectedClass ?? 0]}
				/>}
				{confirmationOpen && <Modal
					id={ID.MODAL}
					className={CLASS.MODAL}
					onClose={() => setConfirmationOpen(false)}
					title='Please confirm character creation.'
					onSubmit={() => {
						saveCharacter(characterForCard, selectedCharacterImage, characterName, user).catch(error => {
							console.error(error);
						});
						setConfirmationOpen(false);
						navigate('/myCharacters');
					}}
				>
					<Label id={ID.LABEL}> Are you sure? </Label>
				</Modal>}
			</div>
			{shouldLoadPreview && <div id={ID.PREVIEW_CONTAINER} className={CLASS.PREVIEW_CONTAINER}>
				<CharacterCard
					id={ID.PREVIEW_CARD}
					className={CLASS.PREVIEW_CARD}
					variant='preview'
					character={characterForCard}
					allAvailablePictures={avaliablePictures}
					setCharacterPicture={imageUrl => setSelectedCharacterImage(imageUrl)}
					isOpen
					onToggle={() => {
					}}
				/>
				<Button id={ID.SUBMIT_BUTTON} className={CLASS.SUBMIT_BUTTON}
					onClick={() => setConfirmationOpen(true)}>
                    Submit
				</Button>
			</div>}
		</>
		}
	</Container>;
}

CharacterCreation.getNames = getNames;

export default CharacterCreation;
