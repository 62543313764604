import React from 'react';
import {Container, Label} from 'iiomi-ui';
import getNames from './getNames';
import useCss from './useCss';
import {useAuth} from '../../firebase';
import {IWebComponentProps} from '../../interfaces/BaseComponentInterface';

interface IHomePageProps extends IWebComponentProps {}

function HomePage(props: IHomePageProps) {
	const {ID, CLASS} = useCss(getNames(props.id));
	const {user} = useAuth();

	return <Container id={ID.ROOT} className={CLASS.ROOT}>
		<Label id={ID.TITLE} className={CLASS.TITLE} tag={'h1'}>
			Welcome to Dragonfire! You are currently {user ? 'logged in. Enjoy your stay!' : 'not logged in'}.
		</Label>
	</Container>;
}

export default HomePage;