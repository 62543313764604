import {css, cssAnimation} from 'iiomi-ui';
import getNames from './getNames';

const { CLASS } = getNames('not_relevant');

const glowAnimation = cssAnimation({
	name: 'glow',
	iterationCount: 'infinite',
	duration: 3000,
	keyframes: {
		'0%': {
			filter: 'drop-shadow(0 0 5px rgba(255, 255, 255, 0.5))',
		},
		'50%': {
			filter: 'drop-shadow(0 0 5px rgba(255, 255, 255, 1))',
		},
		'100%': {
			filter: 'drop-shadow(0 0 5px rgba(255, 255, 255, 0.5))',
		},
	},
});

export default css({
	...glowAnimation.keyframes,
	[CLASS.ROOT]: {
		display: 'flex',
		flexDirection: 'column',
		rowGap: '0.5em',
		padding: '0.5em 1em',
		[css.descendant(CLASS.SLOTS_CONTAINER)]: {
			display: 'flex',
			flexDirection: 'column',
			rowGap: '0.75em',
			[css.descendant(CLASS.SLOT_HEADER)]: {
				display: 'flex',
				flexDirection: 'column',
				[css.descendant(CLASS.SLOT_LABEL_AND_ICON)]: {
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center',
					padding: '0.25em 0.75em',
					[css.descendant(CLASS.DELETE_BUTTON)]: {
						height: '1.8em',
						width: '1.8em',
						padding: '0.2em'
					},
					[css.descendant(CLASS.UNLOCK_BUTTON)]: {
						height: '1.8em',
						width: '1.8em',
						padding: '0.2em',
						[css.descendant.svg]: {
							...glowAnimation.props,
						},
					},
					[css.descendant(CLASS.SELECT_BUTTON)]: {
						height: '1.8em',
						width: '1.8em',
						padding: '0.2em',
					}
				},
			},
			[css.descendant(CLASS.SLOT_CONTENT)]: {
				padding: '0.75em 0.75em',
				display: 'flex',
				flexDirection: 'column',
				gap: '0.5em',
			},
		},
	},
});
