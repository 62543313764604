import React from 'react';
import {Modal, mergeClassNames, ClickElement} from 'iiomi-ui';
import getNames from './getNames';
import useCss from './useCss';
import {IWebComponentProps} from '../../../../interfaces/BaseComponentInterface';

export interface IPictureSelectionModalProps extends IWebComponentProps {
    availablePicture?: string[];
    onClose: () => void;
    onSelectionButtonClick: (selectedPicture: string) => void;
}

function PictureSelectionModal(props: IPictureSelectionModalProps) {
	const { ID, CLASS } = useCss(getNames(props.id));

	return <Modal
		id={ID.ROOT}
		className={mergeClassNames(CLASS.ROOT, props.className)}
		onClose={props.onClose}
		title='Select a picture!'
	>
		<div id={ID.MODAL_BODY} className={CLASS.MODAL_BODY}>
			{props.availablePicture?.map((picture, index) => {
				return <ClickElement
					id={ID.PICTURE_BUTTON}
					key={index}
				>
					<img
						id={ID.PICTURE}
						className={CLASS.PICTURE}
						src={picture}
						onClick={() => props.onSelectionButtonClick(picture)}
						alt={'character-portrait'}
					/>
				</ClickElement>;
			})};
		</div>
	</Modal>;
}

PictureSelectionModal.getNames = getNames;

export default PictureSelectionModal;