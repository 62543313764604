import React from 'react';
import { Button, Modal, mergeClassNames } from 'iiomi-ui';
import { IWebComponentProps } from '../../../interfaces/BaseComponentInterface';
import getNames from './getNames';
import useCss from './useCss';
import { IDatabaseRacesInClassList } from '../../../interfaces/FirebaseInterfaces';

export interface IRaceSelectionModalProps extends IWebComponentProps {
    avaliableRaces: IDatabaseRacesInClassList;
    onClose: () => void;
    onSelectionButtonClick: (selectedRace: string) => void;
}

function RaceSelectionModal(props: IRaceSelectionModalProps) {
	const { ID, CLASS } = useCss(getNames(props.id));

	return <Modal
		id={ID.ROOT}
		className={mergeClassNames(CLASS.ROOT, props.className)}
		onClose={props.onClose}
		title='Select a race!'
	>
		<div id={ID.MODAL_BODY} className={CLASS.MODAL_BODY}>
			{Object.keys(props.avaliableRaces).map((race) => {
				if (race === 'archetype') {
					return null;
				}
				return <Button
					key={race}
					id={ID.RACE_BUTTON}
					className={CLASS.RACE_BUTTON}
					onClick={() => props.onSelectionButtonClick(race)}
				>
					{race}
				</Button>;
			})};
		</div>
	</Modal>;
}

RaceSelectionModal.getNames = getNames;

export default RaceSelectionModal;