import { generateNames } from 'iiomi-ui';

export default (id: string) => generateNames(
	id,
	'NAVIGATION_BAR',
	'DIVIDER',
	'LINK_BOX',
	'LINK',
	'LOGIN_BUTTON',
	'LOGOUT_BUTTON',
);