import React from 'react';

export default function GoldIcon(props: { className?: string }) {
	return <svg
		className={props.className}
		viewBox='0 0 24 24'
	>
		<path
			d='M 12 2 c 5.514 0 10 4.486 10 10 s -4.486 10 -10 10 s -10 -4.486 -10 -10 s 4.486 -10 10 -10 Z m 0 -2 c -6.627 0 -12 5.373 -12 12 s 5.373 12 12 12 s 12 -5.373 12 -12 s -5.373 -12 -12 -12 Z'
			fill='#CCAC00'
		/>
	</svg>;
}
