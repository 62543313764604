import { css } from 'iiomi-ui';
import getNames from './getNames';

const { CLASS } = getNames('not_relevant');

export default css({
	[CLASS.ROOT]: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'flex-start',
		rowGap: '1em',
		padding: '0 0.5em',
		[css.descendant(CLASS.IMAGE_CONTAINER)]: {
			position: 'relative',
			[css.descendant(CLASS.IMAGE_BUTTON)]: {
				top: '0.1em',
				right: '0.1em',
				width: '2em',
				position: 'absolute'
			},
		},
		[css.descendant(CLASS.EFFECTS_CONTAINER)]: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			gap: '0.5em',
			[css.descendant(CLASS.EFFECT_CARD)]: {
				[css.descendant(CLASS.EFFECT_CARD_HEADER)]: {
					display: 'flex',
					flexDirection: 'column',
					[css.descendant(CLASS.EFFECT_CARD_TITLE)]: {
						padding: '0.75em 0.75em',
					},
				},
				[css.descendant(CLASS.EFFECT_CARD_DESCRIPTION)]: {
					padding: '0.75em 0.75em',
				},
			},
		},
	},
});
