import { generateNames } from 'iiomi-ui';

export default (id: string) => generateNames(
	id,
	'LOGIN_PAGE',
	'TITLE',
	'FORM',
	'LABEL',
	'SUBMIT_BUTTON',
	'SIGN_UP',
	'FORGOT_PASSWORD'
);