import React from 'react';
import {Accordion} from 'iiomi-ui';
import {IWebComponentProps} from '../../interfaces/BaseComponentInterface';
import getNames from './getNames';
import useCss from './useCss';
import {CharacterCard} from '../CharacterCard';
import {ICharacterInFirebase} from '../../interfaces/FirebaseInterfaces';

interface IMyCharactersAccordionProps extends IWebComponentProps {
	amount: number;
	characters: ICharacterInFirebase[];
}

function MyCharactersAccordion(props: IMyCharactersAccordionProps) {
	const {ID, CLASS} = useCss(getNames(props.id));
	const shouldFirstIndexBeOpen = props.characters.length > 0;
	console.log('MyCharactersAccordion', props.characters);

	return <Accordion
		id={ID.ROOT}
		className={CLASS.ROOT}
		defaultOpenChildIndex={shouldFirstIndexBeOpen ? 0 : -1}
	>
		{getChildProps => props.characters.map((character, index) => (
			<CharacterCard
				{...getChildProps(index)}
				key={index}
				id={ID.CHARACTER}
				className={CLASS.CHARACTER}
				variant='full'
				character={character}
				collapsible
			/>
		))}
	</Accordion>;
}

export default MyCharactersAccordion;