import { generateNames } from 'iiomi-ui';

export default (id: string) => generateNames(
	id,
	'CLASS_SELECTION_MODAL',
	'MODAL_BODY',
	'CLASS_BUTTON',
	'MARTIAL',
	'DECEPTION',
	'DEVOTION',
	'ARCANE',
);