import { generateNames } from 'iiomi-ui';

export default (id: string) => {
	const names = generateNames(
		id,
		'MY_CHARACTERS_ACCORDION',
		'CHARACTER',
	);

	return {
		ID: {
			...names.ID,
		},
		CLASS: names.CLASS,
	};
};