import { css } from 'iiomi-ui';
import getNames from './getNames';

const { CLASS } = getNames('not_relevant');

export default css({
	[CLASS.ROOT]: {
		width: '25em',
		minHeight: 'auto',
		[css.descendant(CLASS.FEATURE_TITLE)]: {
			padding: '0.25em',
			alignSelf: 'center'
		},
		[css.descendant(CLASS.FEATURE_CARD_BODY)]: {
			flexGrow: '1',
			alignItems: 'center',
			[css.descendant(CLASS.EFFECT_TEXT)]: {
				margin: 'auto',
			},
		},
	},
});