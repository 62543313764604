import {I18n} from '../helpers/i18n';
import defaultEnglish from './en';

export type II18n = typeof defaultEnglish;
let i18n: I18n<II18n> | null = null;

export function getI18n(noCache?: boolean): II18n {
	if (i18n === null || noCache) {
		i18n = new I18n<II18n>({
			en: defaultEnglish,
		});
	}

	return {
		...i18n.get(),
	};
}