import { generateNames } from 'iiomi-ui';

export default (id: string) => generateNames(
	id,
	'CHARACTER_CREATION_PAGE',
	'MAIN_CONTAINER',
	'CHARACTER_NAME_INPUT',
	'TITLE',
	'CHARACTER_ARCHETYPE_BUTTON',
	'CHARCTER_CLASS_BUTTON',
	'CHARACTER_RACE_BUTTON',
	'MODAL',
	'CLASSES_CONTAINER',
	'CLASS_BUTTON',
	'MARTIAL',
	'DECEPTION',
	'DEVOTION',
	'ARCANE',
	'RACES_CONTAINER',
	'RACE_BUTTON',
	'PREVIEW_CONTAINER',
	'PREVIEW_CARD',
	'SUBMIT_BUTTON',
);