const en = {
	LOADING: 'Loading...',
	CREATE: 'Create',
	DELETE: 'Delete',
	ERROR: {
		LOADING_CHARACTERS: 'Error loading characters.',
	},
	CHARACTER_OVERVIEW_HEADER: (currentAmount: number, maxAmount: number) => `My Characters (${currentAmount}/${maxAmount})`,
};

export default en;