import React from 'react';
import {IWebComponentProps} from '../../../interfaces/BaseComponentInterface';
import useCss from './useCss';
import getNames from './getNames';
import {Card, ClickElement, Divider, Label, LockIcon, mergeClassNames, PlusIcon, TrashIcon} from 'iiomi-ui';
import {IFeatureSlot} from '../../../interfaces/FirebaseInterfaces';
import {FeatureSelectModal} from './FeatureSelectModal';
import {useAuth} from '../../../firebase';
import {unlockFeatureSlot} from '../../../helpers/characterHelpers';

export interface IFeatureSlotsProps extends IWebComponentProps {
	featureSlots: number;
	specialFeatureSlots?: 'magicOriginSlot' | 'patronSlot';
	isPreview?: boolean;
	allFeatureSlots?: IFeatureSlot[];
	characterName?: string;
	availableExp?: number;
}

function FeatureSlots(props: IFeatureSlotsProps) {

	const {ID, CLASS} = useCss(getNames(props.id));
	const { user } = useAuth();

	const [isFeatureSelectionOpen, setIsFeatureSelectionOpen] = React.useState<boolean>(false);
	const [selectedSlot, /*setSelectedSlot*/] = React.useState<number>(-1);

	return <div
		id={ID.ROOT}
		className={mergeClassNames(CLASS.ROOT, props.className)}
	>
		<Label
			id={ID.TITLE}
			className={CLASS.TITLE}
			tag={'h4'}
		>
			Feature Slots:
		</Label>
		<div id={ID.SLOTS_CONTAINER} className={CLASS.SLOTS_CONTAINER}>
			{props.allFeatureSlots && props.allFeatureSlots.length > 0
				? props.allFeatureSlots.map((slot, index) => <Card
					id={ID.SLOT}
					className={CLASS.SLOT}
					key={index}
					header={<div id={ID.SLOT_HEADER} className={CLASS.SLOT_HEADER}>
						<div id={ID.SLOT_LABEL_AND_ICON} className={CLASS.SLOT_LABEL_AND_ICON}>
							<Label id={ID.SLOT_LABEL} className={CLASS.SLOT_LABEL} tag={'h6'}>
								{`${getSlotLabel(slot.description)}${slot.selected ? ': ' + slot.selected.name : ''}`}
							</Label>
							{getOperationButton(slot, index, user)}
						</div>
						<Divider id={ID.DIVIDER} className={CLASS.DIVIDER} layout={'horizontal'}/>
					</div>}
				>
					<div id={ID.SLOT_CONTENT} className={CLASS.SLOT_CONTENT}>
						<Label
							id={ID.SLOT_LABEL}
							className={CLASS.SLOT_LABEL}
						>
							{slot.locked ? `Locked - Cost to unlock: ${slot.cost} EXP` : slot.selected ? slot.selected.effectTxt : 'Unlocked'}
						</Label>
					</div>
				</Card>
				) : ([...Array(props.featureSlots)].map((_, index) => <Card
					id={`${ID.SLOT}-${index}`}
					className={CLASS.SLOT}
					key={index}
				>
					{`Feature Slot ${index + 1}`}
				</Card>
				))}
		</div>
		{isFeatureSelectionOpen && <FeatureSelectModal
			id={ID.FEATURE_SELECT_MODAL}
			className={CLASS.FEATURE_SELECT_MODAL}
			onClose={() => setIsFeatureSelectionOpen(false)}
			onSelectionButtonClick={() => {
			}}
			characterName={props.characterName}
			selectedIndex={selectedSlot}
			allFeatureSlots={props.allFeatureSlots}
		/>}
	</div>;

	function getOperationButton(slot: IFeatureSlot, index: number, user: any) {
		if (slot.locked) {
			return <ClickElement
				id={ID.UNLOCK_BUTTON}
				className={CLASS.UNLOCK_BUTTON}
				onClick={() => unlockFeatureSlot(index, user, props.characterName, props.allFeatureSlots, props.availableExp)}
			>
				<LockIcon/>
			</ClickElement>;
		}
		if (!slot.locked && !slot.selected) {
			return <ClickElement
				id={ID.SELECT_BUTTON}
				className={CLASS.SELECT_BUTTON}
				onClick={() => alert('Selecting feature')}
			>
				<PlusIcon/>
			</ClickElement>;
		}
		if (!slot.locked && slot.selected) {
			return <ClickElement
				id={ID.DELETE_BUTTON}
				className={CLASS.DELETE_BUTTON}
				onClick={() => alert('Deleting feature')}
			>
				<TrashIcon variant={'solid'}/>
			</ClickElement>;
		}
		return undefined;
	}
}

function getSlotLabel(label: 'magicOriginSlot' | 'patronSlot' |string) {

	const specialSlotText = {
		magicOriginSlot: 'Magic Origin Slot',
		patronSlot: 'Patron Slot'
	};
	return specialSlotText[label as 'magicOriginSlot' | 'patronSlot'] || label;
}

/*function deleteFeatureSlot(slotIndex: number, user: any, characterName?: string, allFeatureSlots?: IFeatureSlot[]) {
	if (!characterName || !allFeatureSlots) {
		console.error('No character name or feature slots provided');
		return;
	}

	const setFeatureSlot = async () => {
		if (slotIndex < 0 || slotIndex >= allFeatureSlots.length) {
			console.error('Invalid slot index');
			return;
		}

		if ('selected' in allFeatureSlots[slotIndex]) {
			delete allFeatureSlots[slotIndex].selected;
			await updateDoc(doc(fireStore, 'Users', `${user.uid}`, 'Characters', `${characterName}`), {allFeatureSlots: allFeatureSlots});
		} else {
			console.error('No selected property found in the feature slot at index', slotIndex);
		}
	};
	setFeatureSlot();
}*/

FeatureSlots.getNames = getNames;

export default FeatureSlots;
